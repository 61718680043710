import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { loginAsClient } from "apis/auth";

const Login = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const history = useHistory();
  if (!token) history.push("/login");

  const loginClient = async () => {
    if (token) {
      const data = await loginAsClient(token);
      const { result, status } = data;
      if (status && result.token) {
        await localStorage.setItem("authUser", JSON.stringify(result));
        setTimeout(()=>{
          history.push("/dashboard");
        }, [0]);
      } else {
        history.push("/login");
      }
    }
  };

  useEffect(()=>{
    loginClient(token);
  }, [token]);

  return (
    <></>
  );
};
export default withRouter(withTranslation()(Login));

Login.propTypes = {
  history: PropTypes.object,
};
