import { 
  REQ_IB_START, 
  GET_IB_REQUEST_STATUS,
  CREATE_CHANGE_LEVERAGE_REQ_REQUESTED,
  CREATE_CHANGE_LEVERAGE_REQ_SUCCESS,
  CREATE_CHANGE_LEVERAGE_REQ_FAIL, 
  GET_SWAP_REQUEST_STATUS,
  GET_SWAP_FREE_REQUEST_STATUS,
  CREATE_CHANGE_SWAP_REQUESTED,
  CREATE_CHANGE_SWAP_SUCCESS,
  CREATE_CHANGE_SWAP_FAIL
} from "./actionTypes";

export const requestPartnership = (params)=>{
  return {
    type:REQ_IB_START,
    payload:params
  };
};

export const getIbRequestStatus = (params)=>{
  return {
    type:GET_IB_REQUEST_STATUS,
    payload:params
  };
};

// change leverage req
export const createChangeLeverageRequest = (params) => {
  return {
    type: CREATE_CHANGE_LEVERAGE_REQ_REQUESTED,
    payload: params
  };
};
export const createChangeLeverageSuccess = (params) => {
  return {
    type: CREATE_CHANGE_LEVERAGE_REQ_SUCCESS,
    payload: params
  };
};
export const createChangeLeverageFail = (params) => {
  return {
    type: CREATE_CHANGE_LEVERAGE_REQ_FAIL,
    payload: params
  };
};

export const getSwapRequestStatus = (params) => {
  return {
    type: GET_SWAP_REQUEST_STATUS,
    payload: params,
  };
};

export const getSwapFreeRequestStatus = (params) => {
  return {
    type: GET_SWAP_FREE_REQUEST_STATUS,
    payload: params,
  };
};

// change swap req
export const createChangeSwapRequest = (params) => {
  return {
    type: CREATE_CHANGE_SWAP_REQUESTED,
    payload: params,
  };
};
export const createChangeSwapSuccess = (params) => {
  return {
    type: CREATE_CHANGE_SWAP_SUCCESS,
    payload: params,
  };
};
export const createChangeSwapFail = (params) => {
  return {
    type: CREATE_CHANGE_SWAP_FAIL,
    payload: params,
  };
};