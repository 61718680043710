import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { withTranslation } from "react-i18next";

const Slider = (props) => {
  return (
    <div className="w-100" >
      <OwlCarousel className="owl-theme" loop items={1}>
        <div className="w-100 rounded">
          <div className="">
            <img src="img/ca340744-3cc7-4081-bce4-ab551954d989.png" alt="" style={{ 
              width: "100%",
              height: "350px",
              borderRadius:"10px",
              objectFit:"cover"
            }} />
          </div>
        </div>

        <div className="w-100 ">
          <div className="">
            <img src="img/141a7257-d986-4dac-9dfd-4177c1b70193.png" alt="" style={{ 
              width: "100%",
              height: "350px",
              borderRadius:"10px",

            }} />
          </div>
        </div> 

      </OwlCarousel>
    </div>
  );
};

export default withTranslation()(Slider);
