import {
  Col, Row, Container 
} from "reactstrap";
import { withTranslation } from "react-i18next";
import CardWrapper from "components/Common/CardWrapper";
import "./Platform.css";

const Platform = ({  t }) => {
  return <>
    <Container fluid className="platform-container">
      <Row>
        <Col>
          <CardWrapper className="platform-card">
            <div className="header-section">
              <Row>
                <Col md={6}>
                  <div className="qr-section d-flex justify-content-between flex-row">
                    <div className="qr-container">
                      <img src="/img/platforms/apple-dl.png" alt="App Store" className="qr-code"/>
                    </div>
                    <div className="qr-container">
                      <img src="/img/platforms/google-play-dl.png" alt="Google Play" className="qr-code"/>
                    </div>
                    <div className="qr-container">
                      <img src="/img/platforms/app-gallary-dl.png" alt="App Gallery" className="qr-code"/>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="download-section align-items-center mt-3">
                    <div className="d-flex flex-row justify-content-between gap-5 ">
                      <div className="d-flex flex-column gap-3 ms-2">
                        <a href="https://download.mql5.com/cdn/web/prodigy.trading.ltd/mt5/prodigytrading5setup.exe" className="btn text-white hover-grow"><img src="https://www.prodigymarkets.net/wp-content/uploads/2024/11/windows.png" width={"160px"} height={"38"}/></a>
                        <a href="https://download.mql5.com/cdn/web/metaquotes.ltd/mt5/MetaTrader5.pkg.zip?utm_source=www.metatrader5.com&utm_campaign=download.mt5.macos" className="btn text-primary mt-3 hover-grow"><img src="https://www.prodigymarkets.net/wp-content/uploads/2024/11/mac.png" width={"120px"} height={"38px"}/></a>
                      </div>
                      <div className="d-flex flex-column gap-3">
                        <a href="https://web.metatrader.app/terminal" target="_blank" rel="noopener noreferrer" className="btn text-primary  hover-grow"><img src="https://www.prodigymarkets.net/wp-content/uploads/2024/11/web-terminal.png" width={"120px"} height={"38px"}/></a>
                        <a href="https://www.mql5.com/en/articles/625?utm_source=www.metatrader5.com&utm_campaign=download.mt5.linux" target="_blank" rel="noopener noreferrer" className="btn text-primary mt-3  hover-grow"><img src="https://www.prodigymarkets.net/wp-content/uploads/2024/11/linux.png" width={"120px"} height={"38px"}/></a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="content-section d-flex align-items-center justify-content-between w-30">
              <div className="platform-logo ">
                <img src="https://www.prodigymarkets.net/wp-content/uploads/2024/11/mt5.png" alt="MetaTrader 5" height="350px" width="100%"/>
              </div>             

              <div className="w-70">
                <h5 className="platform-title fw-bold">
                  {t("Successful trading starts with convenient and functional trading. MetaTrader 5 is the best choice for the modern trader.")}
                </h5>
                <div className="platform-info d-flex flex-row justify-content-between align-items-center ">
                  <div className="custom-platform">
                    <p className="platform-description custom-p-2 ">
                      {t("With the revolutionary Mobile Trading Platform, you can profit from your trading endeavors and learn at your own pace. Use the MT5 app to access the potent functionality at your disposal.")}
                    </p>
                  </div>
                  <div className="custom-p">
                    <p className="platform-description custom-p ">
                    Meta Trader 5 (MT5) is a robust trading plaƞorm created by <br /> Metaquotes Software for trading forex, stocks,<br />  commodities,and cryptocurrencies. <br /> It provides a wide range of tools for technical and fundamental analysis,<br />  making it a top choice for traders around the globe.<br />  MT5 offers different types of orders,<br />  advanced chart features, and automated trading with Expert Advisors (EAs).
                    </p>
                    <p className="platform-description mt-1 custom-p">
                      {t("MT5 can be downloaded and installed on Windows, macOS, and mobile devices (iOS and Android). Many brokers offer it as a trading platform option, and it’s also available as a web platform for on-the-go trading")}
                    </p>
                  </div>
                </div>

              </div>
            </div>
          </CardWrapper>
        </Col>
      </Row>
    </Container>
  

  </>;
};

export default withTranslation()(Platform);