import CardWrapper from "components/Common/CardWrapper";
import { withTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { mainLogo, clientName } from "content";
import * as socialIcons from "../../assets/images/social-links";

const ForexFooter = (props) => {
  return (
    <Container className="my-4">
      <CardWrapper className="px-5 py-4 shadow glass-card" >
        <p className="text-secondary">
          {props.t(`High Risk Investment Warning: Trading foreign exchange on margin carries a high level of risk, and may not be suitable for all investors. The high degree of leverage can work against you as well as for you. Before deciding to
        trade foreign exchange you carefully consider your investment objectives, level of experience, and risk appetite. The possibility exists that you could sustain a loss of some or all initial investment and
        therefore you should not invest money that you cannot afford to lose. You should be aware of all the the risks associated with foreign exchange trading, and seek from an independent financial adviser if you have any doubts.`)}
        </p>
        <hr className="my-4 text-secondary" />
        <div className="d-flex justify-content-between align-items-center ">
          <div>
            <span>&copy; {props.t("Copyright")} {new Date().getFullYear()} <img src={mainLogo} alt={clientName} height="40" width="80" />, {props.t("All rights reserved")}.</span>
          </div>
          <div className="social-icons">
            <a href="https://www.prodigymarkets.net/">
              <img src={socialIcons.website} alt="" />
            </a>
            <a href="https://www.instagram.com/prodigy.markets/">
              <img src={socialIcons.instagram} alt="" />
            </a>
            <a href="https://www.facebook.com/prodigymarkets">
              <img src={socialIcons.facebook} alt="" />
            </a>
            <a href="https://t.me/prodigymarketsfx">
              <img src={socialIcons.telegram} alt="" />
            </a>
            <a href="https://x.com/prodigy_markets">
              <img src={socialIcons.twitter} alt="" />
            </a>
            <a href="https://www.linkedin.com/in/prodigy-market-ltd-b843812b4/">
              <img src={socialIcons.linkedin} alt="" />
            </a>
            <a href="https://www.tiktok.com/@prodigymarkets?lang=en">
              <img src={socialIcons.tiktok} alt="" />
            </a>
            <a href="https://www.pinterest.com/prodigymarketltd/">
              <img src={socialIcons.pinterest} alt="" />
            </a>
          </div>
        </div>
      </CardWrapper>
    </Container>
  );
};

export default withTranslation()(ForexFooter);